import React from "react"
import { graphql } from "gatsby"
import Button from "../components/Button"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import { DLBAccess } from "../components/Form"
import Icon from "../components/Icon"
import Action from "../components/Resource/Action"
import AnchorNav from "../components/AnchorNav"
import Resource from "../components/Resource"
import OutboundLink from "../components/OutboundLink"
import LeavingSiteWarning from "../components/LeavingSiteWarning"
import Accordion from "../components/Accordion"
import BeTheFirst from "../images/theme/be-the-first.jpg"
import ImplementationTrainingImg from "../images/theme/img-cvs-catch-educators-implementation-train.jpg"
import Elementary_School_Educator_Guide from "../docs/CVS-Catch-E-cigarettes-Risks-Core-ANIMATIONS.pdf"
import Middle_School_Educator_Guide from "../docs/CVS-Catch-6th-Introductions-to-the-Risks-of-E-cigarettes-and-vaping-V6.2.pdf"
import dlbSTEMPdf from "../docs/CVS-Catch-DLB-7-8-STEM-V4.1.pdf"
import dlbHumanitiesPdf from "../docs/CVS-Catch-DLB-7-8-HUMANITIES-V5.2.pdf"
import JuniorHighEducatorGuide from "../docs/CVS-Catch-DLB-7-8-Core-V4.0.pdf"
import High_School_Inhaling_Flavor_Educator_Guide from "../docs/CVS-CATCH-DLB-HS-Inhaling-Flavor-Ed-Guide.pdf"
import High_School_Policy_Change_Educator_Guide from "../docs/CVS-CATCH-DLB-HS-Policy-Change-Ed-Guide.pdf"
import EducatorContext from "../context/EducatorContext"
import CoppaContext from "../context/CoppaContext"
import EpisodePlayer from "../components/EpisodePlayer"
import elemSupplementalSTEM from "../docs/CVS-Catch-E-cigarettes-Risks-STEM-V2.1.pdf"
import elemSupplementalHumanities from "../docs/CVS-Catch-E-cigarettes-Risks-HUMANITIES-ANIMATIONS.pdf"
import "../styles/educatorpage.scss"

const EducatorsPage = ({ data }) => (
  <Layout>
    <SEO title="Educators" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Container>
        <Row>
          <Column span={12}>
            <h1>Educators</h1>
            <h3 className="hero__subhead">
              Empower students to Be Vape Free with standards-aligned, no-cost
              resources
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  INTRO ***************/}
    <Section className="lightgray intro">
      <Container>
        <Row>
          <Column span={10}>
            <h2 className="serif">Access the Resources</h2>
            <p>
              We invite you to explore CATCH My Breath, an evidence-based
              nicotine vaping prevention program with impact. Get started today
              by signing up for the CATCH My Breath Core Program Bundle,
              available for elementary school and middle school educators.
              Supplementary lesson bundles are designed to be interdisciplinary
              and can be incorporated into everyday classroom curriculum. High
              School resources are available at{" "}
              <OutboundLink to="https://www.catch.org/bundles/23725">
                CATCH.org.
              </OutboundLink>
            </p>
          </Column>
        </Row>
      </Container>

      {/***************  ELEMENTARY SCHOOL SIGNUP ***************/}
      <Container>
        <Row>
          <Column span={12}>
            <AnchorNav />
          </Column>
        </Row>
        <Row className="implementation-training">
          <Column span={4}>
            <img
              alt="Implementation Training"
              src={ImplementationTrainingImg}
            />
          </Column>
          <Column span={8}>
            <h2>Implementation Training</h2>
            <p>
              Looking for tips on program implementation best practices? We’ve
              got you covered. Get the latest on the youth vaping epidemic,
              e-cigarette laws and policies, plus a distance learning guide to
              prepare you to deliver the CATCH My Breath program in any
              educational setting.
            </p>
            <p>
              <LeavingSiteWarning
                url="https://www.catch.org/bundles/23725#training"
                trigger={
                  <span>
                    <Button>
                      For training options, please visit CATCH.org
                      <Icon name="externallink" />
                    </Button>
                  </span>
                }
              />
            </p>
          </Column>
        </Row>
        <h3 className="core-heading" name="ElementarySchool">
          Elementary School
        </h3>
        <div className="core-program-bundle" id="classroomResources">
          <Row>
            <Column span={6}>
              <CoppaContext.Consumer>
                {({ coppaRestricted }) => (
                  <EducatorContext.Consumer>
                    {({
                      highSchoolProcessed,
                      middleSchoolProcessed,
                      elementarySchoolProcessed,
                    }) => (
                      <Resource
                        label="Core Program Bundle"
                        image="img-core-program-bundle-elementary.jpg"
                        subjects={["Health"]}
                        title="Introduction to the Risks of E-Cigarettes and Vaping"
                        grade="5th Grade"
                        duration="Four sessions, 30-40 minutes each"
                        description={
                          <>
                            <p>
                              In this core bundle, students will identify the
                              health risks of e-cigarette use, learn about the
                              hazardous chemicals involved, analyze tobacco
                              marketing tactics, and develop, practice, and
                              demonstrate refusal skills.{" "}
                              <strong>
                                It is recommended that all schools deliver this
                                core bundle as a minimum and supplement as
                                desired with the additional bundles below.
                              </strong>
                            </p>
                            <p>
                              <em>
                                Submit the form to gain immediate access to the
                                CATCH My Breath core program content.
                              </em>
                            </p>
                          </>
                        }
                        actions={[
                          {
                            label: "Educator Guide",
                            gradeLevel: "elementary",
                            path: Elementary_School_Educator_Guide,
                            // path:
                            //   "https://be-vape-free.s3.us-east-2.amazonaws.com/educator-guides/CATCH+My+Breath+Core+Program+Bundle+Educator+Guide+-+Elementary.pdf",
                            type: "download",
                            locked:
                              coppaRestricted ||
                              !(
                                highSchoolProcessed ||
                                middleSchoolProcessed ||
                                elementarySchoolProcessed
                              ),
                          },
                          {
                            label: "Class Slides",
                            gradeLevel: "elementary",
                            path:
                              "https://docs.google.com/presentation/d/1OVg_7RuOabEyIUIbpPWOJg1dVCIHi6pX/view",
                            type: "download",
                            // locked:
                            //   coppaRestricted ||
                            //   !(
                            //     highSchoolProcessed ||
                            //     middleSchoolProcessed ||
                            //     elementarySchoolProcessed
                            //   ),
                          },
                        ]}
                      />
                    )}
                  </EducatorContext.Consumer>
                )}
              </CoppaContext.Consumer>
            </Column>
            <Column span={6}>
              <h2>
                Sign up for immediate access to CATCH My Breath core program
                content
              </h2>
              <CoppaContext.Consumer>
                {({ coppaRestricted }) =>
                  coppaRestricted ? (
                    <p>
                      Only users that are 13 years of age or older can subscribe
                      to updates!
                    </p>
                  ) : (
                    <EducatorContext.Consumer>
                      {({
                        highSchoolProcessed,
                        middleSchoolProcessed,
                        elementarySchoolProcessed,
                        markElementarySchoolAsProcessed,
                      }) =>
                        !(
                          highSchoolProcessed ||
                          middleSchoolProcessed ||
                          elementarySchoolProcessed
                        ) ? (
                          <DLBAccess
                            id="Elementary School"
                            onSubmit={() => markElementarySchoolAsProcessed()}
                          />
                        ) : (
                          <p>
                            Thank you for submitting your information. The core
                            CATCH My Breath content is now available for
                            download.
                          </p>
                        )
                      }
                    </EducatorContext.Consumer>
                  )
                }
              </CoppaContext.Consumer>
            </Column>
          </Row>
        </div>
      </Container>
    </Section>

    {/***************  RESOURCES ***************/}
    <Section className="lightgray educator__resources">
      <Resource
        label="Supplemental Science Bundle"
        image="img-supplemental-science-bundle.jpg"
        subjects={["Science", "Health"]}
        title="Risks of E-Cigarettes"
        grade="Upper Elementary"
        description={
          <p>
            In the Supplemental Science Bundle, students will dive into
            important questions such as, “what parts of the brain and body are
            affected by e-cigarette use” and “what harmful and toxic chemicals
            can be found in e-cigarettes.”
          </p>
        }
        actions={[
          {
            label: "Educator Guide PDF",
            path: elemSupplementalSTEM,
            type: "download",
          },
          {
            label: "Class Slides PPT",
            path:
              "https://docs.google.com/presentation/d/1iCjK6oDUQMyqiwbxL_tH89fijF5Ve7hNWedsMsSiVCw/view",
            type: "download",
          },
        ]}
        bgpattern
      />

      <Resource
        label="Supplemental Humanities Bundle"
        image="img-supplemental-humanities-bundle.jpg"
        subjects={["Social Studies", "English-Language Arts"]}
        title="E-Cigarette Laws &amp; My Community"
        grade="Upper Elementary"
        description={
          <p>
            In the Supplemental Humanities Bundle, students will explore how
            states are addressing the vaping crisis through legislation around
            the sale, purchase, and use of e-cigarettes. Students will
            investigate the evolution of their state’s laws & the purposes
            behind enacting those laws.
          </p>
        }
        actions={[
          {
            label: "Educator Guide PDF",
            path: elemSupplementalHumanities,
            type: "download",
          },
          {
            label: "Class Slides PPT",
            path:
              "https://docs.google.com/presentation/d/1ay1u7qA7oP0sKZ8OwwscPzPSIiZJZ5zewQcD1k7ryPQ/view",
            type: "download",
          },
        ]}
        bgpatterntwo
      />

      {/***************  SIGN UP FOR UPDATES ***************/}
    </Section>

    <Section id="videos" className="lightgray">
      <Container>
        <Row>
          <Column span={12}>
            <EpisodePlayer
              episodes={data.allSpmEpisodesYaml.edges.map(edge => edge.node)}
              playerLabel="Self-Paced Module Videos"
              intro={
                <>
                  <p>
                    Encourage your students to investigate the truth and
                    consequences of vaping and e-cigarette use. View the videos
                    together with your students using the standards-aligned
                    lesson plans and educator guide or assign it for them to
                    explore independently using the self-paced module.
                  </p>
                  <p className="spm-intro-links">
                    <Action
                      type="download"
                      label="Educator Guide"
                      path="/resources/docs/spm/CVS-Catch-Vape-Myths-EdGuide.pdf"
                    />
                    <Action
                      type="external"
                      label="Student Self-Paced Module"
                      url="/cvs-module"
                    />
                  </p>
                </>
              }
            />
          </Column>
        </Row>
      </Container>
    </Section>

    <Section className="lightgray intro">
      {/***************  MIDDLE SCHOOL SIGNUP ***************/}
      <Container>
        <h3 className="core-heading" name="MiddleSchool">
          Middle School
        </h3>
        <div className="core-program-bundle" id="classroomResources">
          <Row>
            <Column span={6}>
              <CoppaContext.Consumer>
                {({ coppaRestricted }) => (
                  <EducatorContext.Consumer>
                    {({
                      highSchoolProcessed,
                      middleSchoolProcessed,
                      elementarySchoolProcessed,
                    }) => (
                      <Resource
                        label="Core Program Bundle"
                        image="img-core-program-bundle-middle.jpg"
                        subjects={["Health"]}
                        title="Introduction to the Risks of E-Cigarettes and Vaping"
                        grade="6th Grade"
                        duration="Four sessions, 30-40 minutes each"
                        description={
                          <>
                            <p>
                              In this core bundle, students will identify the
                              health risks of e-cigarette use, learn about the
                              hazardous chemicals involved, analyze tobacco
                              marketing tactics, and develop, practice, and
                              demonstrate refusals skills.{" "}
                              <strong>
                                It is recommended that all schools deliver this
                                core bundle as a minimum and supplement as
                                desired with the additional bundles.
                              </strong>
                            </p>
                            <p>
                              <em>
                                Submit the form to gain immediate access to the
                                CATCH My Breath core program content.
                              </em>
                            </p>
                          </>
                        }
                        actions={[
                          {
                            label: "Educator Guide",
                            gradeLevel: "middle",
                            path: Middle_School_Educator_Guide,
                            type: "download",
                            locked:
                              coppaRestricted ||
                              !(
                                highSchoolProcessed ||
                                middleSchoolProcessed ||
                                elementarySchoolProcessed
                              ),
                          },
                          {
                            label: "Class Slides",
                            gradeLevel: "middle",
                            path:
                              "https://docs.google.com/presentation/d/1YZQ0rVsj8aQsFn4h7Z5k0-4kRiAB3wNQ/view",
                            type: "download",
                            logName:
                              "Risks of E-Cigarettes and Vaping.pptx - MiddleSchool",
                            // locked:
                            //   coppaRestricted ||
                            //   !(
                            //     highSchoolProcessed ||
                            //     middleSchoolProcessed ||
                            //     elementarySchoolProcessed
                            //   ),
                          },
                        ]}
                      />
                    )}
                  </EducatorContext.Consumer>
                )}
              </CoppaContext.Consumer>
            </Column>
            <Column span={6}>
              <h2>
                Sign up for immediate access to CATCH My Breath core program
                content
              </h2>
              <CoppaContext.Consumer>
                {({ coppaRestricted }) =>
                  coppaRestricted ? (
                    <p>
                      Only users that are 13 years of age or older can subscribe
                      to updates!
                    </p>
                  ) : (
                    <EducatorContext.Consumer>
                      {({
                        highSchoolProcessed,
                        middleSchoolProcessed,
                        elementarySchoolProcessed,
                        markMiddleSchoolAsProcessed,
                      }) =>
                        !(
                          highSchoolProcessed ||
                          middleSchoolProcessed ||
                          elementarySchoolProcessed
                        ) ? (
                          <DLBAccess
                            id="Middle School"
                            onSubmit={() => markMiddleSchoolAsProcessed()}
                          />
                        ) : (
                          <p>
                            Thank you for submitting your information. The core
                            CATCH My Breath content is now available for
                            download.
                          </p>
                        )
                      }
                    </EducatorContext.Consumer>
                  )
                }
              </CoppaContext.Consumer>
            </Column>
          </Row>
        </div>
        <div className="core-program-bundle core-program-bundle--vaping-and-your-life">
          <Row>
            <Column span={6}>
              <CoppaContext.Consumer>
                {({ coppaRestricted }) => (
                  <EducatorContext.Consumer>
                    {({
                      highSchoolProcessed,
                      middleSchoolProcessed,
                      elementarySchoolProcessed,
                    }) => (
                      <Resource
                        label="Core Program Bundle"
                        image="thmb-dlb-7-8-grade_2x.jpg"
                        subjects={["Health"]}
                        title="Vaping &amp; Your Life: A Guide To Making Informed Choices"
                        grade="7th-8th Grade"
                        duration="Four sessions, 30-40 minutes each"
                        description={
                          <>
                            <p>
                              In this core bundle, students will identify the
                              health risks of e-cigarette use, learn about the
                              hazardous chemicals involved, develop, practice,
                              and demonstrate refusals skills, and analyze
                              tobacco marketing tactics to build anti-vaping
                              posters.{" "}
                              <strong>
                                It is recommended that all schools deliver this
                                core bundle as a minimum and supplement as
                                desired with the additional bundles.
                              </strong>
                            </p>
                            <p>
                              <em>
                                Submit the form to gain immediate access to the
                                CATCH My Breath core program content.
                              </em>
                            </p>
                          </>
                        }
                        actions={[
                          {
                            label: "Educator Guide",
                            gradeLevel: "middle",
                            path: JuniorHighEducatorGuide,
                            type: "download",
                            locked:
                              coppaRestricted ||
                              !(
                                highSchoolProcessed ||
                                middleSchoolProcessed ||
                                elementarySchoolProcessed
                              ),
                          },
                          {
                            label: "Class Slides",
                            gradeLevel: "middle",
                            path:
                              "https://docs.google.com/presentation/d/1A8CE7yBcWWI9iKs-dTHdpPLVQ19gOEDe_6FkCIdA9ho/view",
                            type: "download",
                            logName: "Vaping & Your Life.ppx - Junior High",
                            // locked:
                            //   coppaRestricted ||
                            //   !(
                            //     highSchoolProcessed ||
                            //     middleSchoolProcessed ||
                            //     elementarySchoolProcessed
                            //   ),
                          },
                        ]}
                      />
                    )}
                  </EducatorContext.Consumer>
                )}
              </CoppaContext.Consumer>
            </Column>
            <Column span={6}>
              <h2>
                Sign up for immediate access to CATCH My Breath core program
                content
              </h2>
              <CoppaContext.Consumer>
                {({ coppaRestricted }) =>
                  coppaRestricted ? (
                    <p>
                      Only users that are 13 years of age or older can subscribe
                      to updates!
                    </p>
                  ) : (
                    <EducatorContext.Consumer>
                      {({
                        highSchoolProcessed,
                        middleSchoolProcessed,
                        elementarySchoolProcessed,
                        markMiddleSchoolAsProcessed,
                      }) =>
                        !(
                          highSchoolProcessed ||
                          middleSchoolProcessed ||
                          elementarySchoolProcessed
                        ) ? (
                          <DLBAccess
                            id="Middle School"
                            onSubmit={() => markMiddleSchoolAsProcessed()}
                          />
                        ) : (
                          <p>
                            Thank you for submitting your information. The core
                            CATCH My Breath content is now available for
                            download.
                          </p>
                        )
                      }
                    </EducatorContext.Consumer>
                  )
                }
              </CoppaContext.Consumer>
            </Column>
          </Row>
        </div>
      </Container>
    </Section>
    <Section className="lightgray educator__resources pt-0">
      <Resource
        label="Supplemental Science Bundle"
        image="dlb-stem-refuse_2x.jpg"
        subjects={["Science", "Health"]}
        title="Refuse to Lose: E-Cigarette Realities"
        grade="Middle School"
        description={
          <p>
            In the Supplemental Science Bundle, students will learn that
            e-cigarettes produce aerosols composed of potentially harmful
            chemicals, including nicotine, and about the damage that nicotine
            can do to the brain and body.
          </p>
        }
        actions={[
          {
            label: "Educator Guide PDF",
            path: dlbSTEMPdf,
            type: "download",
          },
          {
            label: "Class Slides PPT",
            path:
              "https://docs.google.com/presentation/d/13NvrKllnfRVCL-w-zvJYHJ1JwZwlhyDc/view",
            type: "download",
          },
        ]}
        bgpattern
      />
      <Resource
        label="Supplemental Humanities Bundle"
        image="dlb-stem-take-charge_2x.jpg"
        subjects={["SOCIAL STUDIES", "ENGLISH-LANGUAGE ARTS"]}
        title="Take Charge of your Health: The Truth About E-Cigarettes"
        grade="Middle School"
        description={
          <p>
            In the Supplemental Humanities Bundle, students will investigate
            credible and non-credible sources of health information to learn how
            to identify valid sources of information in order to make healthy
            decisions for themselves.
          </p>
        }
        actions={[
          {
            label: "Educator Guide PDF",
            path: dlbHumanitiesPdf,
            type: "download",
          },
          {
            label: "Class Slides PPT",
            path:
              "https://docs.google.com/presentation/d/1qW4HLN3UhEM6dWzOQPKGLXTjniyEyzhn/view",
            type: "download",
          },
        ]}
        bgpattern
      />
      <Resource
        label="Self-Paced Module"
        image="risks-of-vaping.jpg"
        subjects={["Science", "Health"]}
        title="Vaping Risks Revealed"
        grade="Middle School"
        duration="Duration: 20-25 min."
        description={
          <p>
            In this self-paced module, students will explore the risks and
            consequences of vaping or using e-cigarettes.
          </p>
        }
        actions={[
          {
            label: "Get Started",
            url: "/vaping-risks-revealed/",
            type: "external",
          },
          {
            type: "download",
            label: "Educator Guide",
            logName:
              "/resources/docs/spm/CVS-Catch-Vaping-Risks-Revealed-EdGuide.pdf",
            path: "/resources/docs/spm/CVS-Catch-BVF-SPM-V1.2.pdf",
          },
        ]}
        horizontal
      />
    </Section>
    <div className="toolkit-partnerships-container">
      {/***************  HIGH SCHOOL SIGNUP ***************/}
      <Section className="lightgray educator__resources">
        <Container>
          <h3 className="core-heading" name="HighSchool">
            High School
          </h3>
          <div className="core-program-bundle" id="classroomResources">
            <Row>
              <Column span={6}>
                <CoppaContext.Consumer>
                  {({ coppaRestricted }) => (
                    <EducatorContext.Consumer>
                      {({
                        highSchoolProcessed,
                        middleSchoolProcessed,
                        elementarySchoolProcessed,
                      }) => (
                        <Resource
                          label="Core Program Bundle"
                          image="img-core-program-bundle-high.jpg"
                          subjects={["Health"]}
                          title="Fact or Fiction: The Truth About Vaping"
                          grade="High School"
                          duration="Four sessions, 30-40 minutes each"
                          description={
                            <>
                              <p>
                                In this core bundle, students will learn about
                                the consequences of using e-cigarettes, identify
                                the signs of addiction, learn how to offer and
                                seek help, and make the decision to not use
                                e-cigarettes now or in the future.{" "}
                                <strong>
                                  It is recommended that all schools deliver
                                  this core bundle as a minimum and supplement
                                  as desired with the additional
                                  <br /> bundles below.
                                </strong>
                                <br />
                                <em>
                                  Submit the form to gain immediate access to
                                  the CATCH My Breath core program content.
                                </em>
                              </p>
                            </>
                          }
                          actions={[
                            {
                              label: "Educator Guide",
                              gradeLevel: "high",
                              path:
                                "https://docs.google.com/document/d/1KDAOalmc-zk4f3LhWm9pp6csEREG_Fk0BFNXSN_SxXI/export?format=pdf",
                              type: "download",
                              locked:
                                coppaRestricted ||
                                !(
                                  highSchoolProcessed ||
                                  middleSchoolProcessed ||
                                  elementarySchoolProcessed
                                ),
                            },
                            {
                              label: "Class Slides",
                              gradeLevel: "high",
                              path:
                                "https://docs.google.com/presentation/d/11FDWLf2gJQf6ZEs5LcCTOceFGM_u4RAo3GCY0ckteMw/export/pptx",
                              type: "download",
                              locked:
                                coppaRestricted ||
                                !(
                                  highSchoolProcessed ||
                                  middleSchoolProcessed ||
                                  elementarySchoolProcessed
                                ),
                            },
                            {
                              label: "Student Workbook",
                              gradeLevel: "high",
                              path:
                                "https://docs.google.com/document/d/1bMJnI4jnVGVihGkjQ8yjz9RoVgTBM_fZx7nGgTct168/export?format=pdf",
                              type: "download",
                              locked:
                                coppaRestricted ||
                                !(
                                  highSchoolProcessed ||
                                  middleSchoolProcessed ||
                                  elementarySchoolProcessed
                                ),
                            },
                            {
                              label: "Homework: Session 1",
                              gradeLevel: "high",
                              path:
                                "https://docs.google.com/document/d/15JmjbruZIeFihxSkxgdrtjVC_hybVqCdIZW8tqY-O8E/export?format=pdf",
                              type: "download",
                              locked:
                                coppaRestricted ||
                                !(
                                  highSchoolProcessed ||
                                  middleSchoolProcessed ||
                                  elementarySchoolProcessed
                                ),
                            },
                            {
                              label:
                                "Adaptation for Teaching Core Program in PE Class",
                              gradeLevel: "high",
                              path:
                                "https://docs.google.com/document/d/1NEBEkehW6G9Bx1dLxOm43l5sOwx2RyT6owb1O4VO_d0/export?format=pdf",
                              type: "download",
                              locked:
                                coppaRestricted ||
                                !(
                                  highSchoolProcessed ||
                                  middleSchoolProcessed ||
                                  elementarySchoolProcessed
                                ),
                            },
                          ]}
                        />
                      )}
                    </EducatorContext.Consumer>
                  )}
                </CoppaContext.Consumer>
              </Column>
              <Column span={6}>
                <h2>
                  Sign up for immediate access to CATCH My Breath core program
                  content
                </h2>
                <CoppaContext.Consumer>
                  {({ coppaRestricted }) =>
                    coppaRestricted ? (
                      <p>
                        Only users that are 13 years of age or older can
                        subscribe to updates!
                      </p>
                    ) : (
                      <EducatorContext.Consumer>
                        {({
                          highSchoolProcessed,
                          middleSchoolProcessed,
                          elementarySchoolProcessed,
                          markHighSchoolAsProcessed,
                        }) =>
                          !(
                            highSchoolProcessed ||
                            middleSchoolProcessed ||
                            elementarySchoolProcessed
                          ) ? (
                            <DLBAccess
                              id="High School"
                              onSubmit={() => markHighSchoolAsProcessed()}
                            />
                          ) : (
                            <p>
                              Thank you for submitting your information. The
                              core CATCH My Breath content is now available for
                              download.
                            </p>
                          )
                        }
                      </EducatorContext.Consumer>
                    )
                  }
                </CoppaContext.Consumer>
              </Column>
            </Row>
          </div>
        </Container>
        <Section>
          <Resource
            label="Supplemental Science Bundle"
            image="dlb-stem-inhale-flavor.jpg"
            subjects={["SCIENCE, HEALTH"]}
            title="Inhaling Flavor"
            grade="High School"
            description={
              <p>
                In this supplemental science bundle, students in grades 9-12
                will investigate the science of taste and flavor, their
                correlation to vaping addiction, and the effects of e-cigarettes
                on the human body.
              </p>
            }
            actions={[
              {
                label: "Educator Guide PDF",
                path: High_School_Inhaling_Flavor_Educator_Guide,
                type: "download",
              },
              {
                label: "Class Slides PPT",
                path:
                  "https://docs.google.com/presentation/d/1qtSltjTiCLl7o64gXOEkUa2EBGdZdt0VPAqO1yD9itM/view",
                type: "download",
              },
            ]}
            bgpattern
          />
          <Resource
            label="Supplemental Humanities Bundle"
            image="img-dlb-policy-loopholes.jpg"
            subjects={["SCIENCE, HEALTH"]}
            title="Policy Loopholes"
            grade="High School"
            description={
              <p>
                In this supplemental humanities bundle, students in grades 9–12
                will investigate the effects of healthcare policies, laws, and
                loopholes on public health.
              </p>
            }
            actions={[
              {
                label: "Educator Guide PDF",
                path: High_School_Policy_Change_Educator_Guide,
                type: "download",
              },
              {
                label: "Class Slides PPT",
                path:
                  "https://docs.google.com/presentation/d/1iaMscsN3MklzCnvslqpOlRQGmM0M06OLCDrO3yfRVec/view",
                type: "download",
              },
            ]}
            bgpattern
          />
          <Resource
            label="Self-Paced Module"
            image="Living-Vape-free.png"
            subjects={["Science", "Health"]}
            title="Empowering Yourself and Others to Be Vape Free"
            grade="High School"
            duration="Duration: 20-25 min."
            description={
              <p>
                In this self-paced module, students will learn how to empower
                themselves and others to stay vape free. They will discover how
                to be informed consumers and critical observers while using
                social media.
              </p>
            }
            actions={[
              {
                label: "Get Started",
                url: "/empower-yourself/",
                type: "external",
              },
              {
                type: "download",
                label: "Educator Guide",
                logName:
                  "/resources/docs/spm/CVS-Catch-Be-Vape-Free-EdGuide.pdf",
                path: "/resources/docs/spm/CVS-CATCH-HS-SPM-FINAL.pdf",
              },
            ]}
            horizontal
          />
        </Section>
      </Section>
      {/***************  PARTNERSHIPS ***************/}
      <Section className="yellow-pattern">
        <Container>
          <Row>
            <Column span={10}>
              <h2>CVS Health Foundation Partnerships </h2>
              <p>
                In addition to <strong>Be Vape Free</strong>, the CVS Health
                Foundation is partnering with best-in-class organizations to
                deliver the first tobacco-free generation. Explore these
                partners’ engaging resources that help students quit smoking and
                vaping, and that help to ensure that those who haven’t started,
                never will.
              </p>
            </Column>
          </Row>
        </Container>
        <Container>
          <Row>
            <Column span={8}>
              <Accordion
                title="Yale’s play2PREVENT Lab: smokeSCREEN Game"
                body={
                  <p>
                    With a focus on behavior change, education, health,
                    well-being, and social intelligence, the smokeSCREEN Game
                    delivers an effective intervention to promote healthy lives
                    for youth and young adults.
                    <br />
                    <OutboundLink to="https://www.smokescreengame.org" withIcon>
                      smokeSCREENGame.org
                    </OutboundLink>
                  </p>
                }
              />
              <Accordion
                title="Campaign for Tobacco-Free Kids: Taking Down Tobacco Online Advocacy Training"
                body={
                  <p>
                    For more than 20 years, the Campaign for Tobacco-Free Kids
                    has fought to protect children and save lives from the #1
                    cause of preventable death: tobacco use. These in-depth
                    trainings help young leaders take their advocacy to the next
                    level.
                    <br />
                    <OutboundLink
                      to="https://www.takingdowntobacco.org"
                      withIcon
                    >
                      www.takingdowntobacco.org
                    </OutboundLink>
                  </p>
                }
              />
              <Accordion
                title="Truth Initiative: This is Quitting"
                body={
                  <p>
                    Truth Initiative is America’s largest nonprofit public
                    health organization dedicated to making tobacco use a thing
                    of the past. Designed specifically for teens and young
                    adults, This is Quitting is a free quit-vaping text message
                    program that is one of the few resources available to young
                    people.
                    <br />
                    <OutboundLink
                      to="https://www.truthinitiative.org/thisisquitting"
                      withIcon
                    >
                      www.truthinitiative.org
                    </OutboundLink>
                  </p>
                }
              />
              <Accordion
                title="The American Lung Association: Communities in Action"
                body={
                  <p>
                    The American Lung Association is leading the way in helping
                    all Americans breathe easier. We are America’s trusted
                    source for lung health education, lung disease research,
                    support, programs, services and advocacy. Access resources
                    to help create tobacco-free schools.
                    <br />
                    <OutboundLink to="https://www.lung.org/cvs" withIcon>
                      www.lung.org/cvs
                    </OutboundLink>
                  </p>
                }
              />
              <Accordion
                title="DoSomething.org: The Hit We’ll Take Campaign"
                body={
                  <p>
                    DoSomething.org is mobilizing young people in every US area
                    code to sign up for a volunteer, social change, or civic
                    action campaign to make real-world impact. The Hit We'll
                    Take Campaign empowers young people to join the movement to
                    make the next generation tobacco and vape-free. <br />
                    <OutboundLink
                      to="https://www.dosomething.org/us/campaigns/the-hit-well-take"
                      withIcon
                    >
                      www.dosomething.org
                    </OutboundLink>
                  </p>
                }
              />
              <Accordion
                title="American Cancer Society: Tobacco-Free Generation Campuses Initiative"
                body={
                  <p>
                    The Tobacco-Free Generation Campus Initiative provides
                    generous grants to accelerate and expand the adoption and
                    implementation of 100% smoke- and tobacco-free policies on
                    college campuses across the nation. <br />
                    <OutboundLink
                      to="https://www.tobaccofreecampus.org"
                      withIcon
                    >
                      www.tobaccofreecampus.org
                    </OutboundLink>
                  </p>
                }
              />
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  ADDITIONAL RESOURCES ***************/}
      <Section className="additional-resources">
        <Container>
          <h2 id="additionalResources">Additional Resources</h2>
          <p>
            Find the latest resources from the foremost leaders in public health
            regarding e-cigarettes.
          </p>
          <Row>
            <Column span={8}>
              <Accordion
                title="CDC Resources"
                body={
                  <div>
                    <OutboundLink
                      to="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/Quick-Facts-on-the-Risks-of-E-cigarettes-for-Kids-Teens-and-Young-Adults.html"
                      withIcon
                    >
                      Quick Facts on the Risks of E-cigarettes for Kids, Teens,
                      and Young Adults
                    </OutboundLink>
                    <OutboundLink
                      to="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/severe-lung-disease.html"
                      withIcon
                    >
                      Outbreak of Lung Injury Associated with the Use of
                      E-Cigarette, or Vaping, Products
                    </OutboundLink>
                  </div>
                }
              />

              <Accordion
                title="Food and Drug Administration"
                body={
                  <div>
                    <OutboundLink
                      to="https://www.fda.gov/news-events/public-health-focus/lung-illnesses-associated-use-vaping-products"
                      withIcon
                    >
                      Lung Illnesses Associated with Use of Vaping Products
                    </OutboundLink>
                    <OutboundLink
                      to="https://www.fda.gov/tobacco-products/fda-launches-new-campaign-real-cost-youth-e-cigarette-prevention-campaign"
                      withIcon
                    >
                      FDA Launches New Campaign: 'The Real Cost' Youth
                      E-Cigarette Prevention Campaign
                    </OutboundLink>
                    <OutboundLink
                      to="https://www.fda.gov/tobacco-products/public-health-education/real-cost-campaign"
                      withIcon
                    >
                      The Real Cost Campaign
                    </OutboundLink>
                  </div>
                }
              />

              <Accordion
                title="Surgeon General"
                body={
                  <div>
                    <OutboundLink
                      to="https://e-cigarettes.surgeongeneral.gov"
                      withIcon
                    >
                      Know the Risks: E-cigarettes and Young People
                    </OutboundLink>
                    <OutboundLink
                      to="https://e-cigarettes.surgeongeneral.gov/documents/surgeon-generals-advisory-on-e-cigarette-use-among-youth-2018.pdf"
                      withIcon
                    >
                      Surgeon General's Advisory on E-cigarette Use Among Youth
                    </OutboundLink>
                    <OutboundLink
                      to="https://e-cigarettes.surgeongeneral.gov/documents/2016_sgr_full_report_non-508.pdf"
                      withIcon
                    >
                      E-Cigarette Use Among Youth and Young Adults – A Report of
                      the Surgeon General
                    </OutboundLink>
                  </div>
                }
              />
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  BE THE FIRST INITIATIVE ***************/}
      <Section className="be-the-first lightgray">
        <Container>
          <Row>
            <Column span={6}>
              <img
                src={BeTheFirst}
                alt="Catch My Breath"
                className="catch__Promo"
              />
            </Column>
            <Column span={6}>
              <h2>Be The First Initiative</h2>
              <p>
                Five years ago, CVS Health made the decision to stop selling
                cigarettes and tobacco products in all CVS Pharmacy locations in
                an effort to build an innovative health care company driven by a
                purpose — helping people on their path to better health. Since
                then, CVS Health and the CVS Health Foundation have launched a
                multi-year $50 million initiative called 'Be The First' to help
                create the first tobacco-free generation through advocacy,
                education, and healthy behavior programming.
              </p>
              <OutboundLink to="https://www.cvshealth.com/BeTheFirst" withIcon>
                www.cvshealth.com/BeTheFirst ›
              </OutboundLink>
            </Column>
          </Row>
        </Container>
      </Section>
    </div>
  </Layout>
)

export const query = graphql`
  query EducatorsPageQuery {
    allSpmEpisodesYaml {
      edges {
        node {
          id
          actions {
            label
            path
            type
          }
          guidSrc
          duration
          gradeLevel
          posterSrc
          subject
          title
        }
      }
    }
  }
`

export default EducatorsPage
