import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Video from "../Video"
import Action from "../Resource/Action"
import Icon from "../Icon"
import Image from "../Image"
import "./styles.scss"

const EpisodePlayer = ({ episodes, playerLabel, intro, className }) => {
  const [activeIndex, setActiveIndex] = useState(0) // zero-based index
  const viewerRef = useRef(null)

  const renderEpisodes = () => {
    return (
      <>
        {episodes.map((episode, index) => {
          return (
            <button
              key={index}
              className={classNames(
                "episode-player__episode",
                index === activeIndex && "episode-player__episode--active"
              )}
              onClick={() => {
                setActiveIndex(index)
                if (viewerRef.current.getBoundingClientRect()["top"] < 0) {
                  viewerRef.current.scrollIntoView({ behavior: "smooth" })
                }
              }}
            >
              <div className="episode__image">
                <Image filename={episode.posterSrc} />
              </div>
              <h5>{episode.title}</h5>
              {episode.description && <p>{episode.description}</p>}
            </button>
          )
        })}
      </>
    )
  }

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex]
    return <Video guidSrc={episode.guidSrc} />
  }

  const renderCurrentData = () => {
    const episode = episodes[activeIndex]
    return (
      <>
        <span className="episode-player__subject">{episode.subject}</span>
        <h3>{episode.title}</h3>
        <span className="episode-player__grade-level">
          {episode.gradeLevel}
        </span>
        <span className="episode-player__duration">
          <Icon name="clock" />
          {episode.duration}
        </span>
        <p>{episode.description}</p>
        {episode.actions && (
          <div className="episode-player__actions">
            {episode.actions.map((action, i) => (
              <Action
                key={i}
                type={action.type}
                path={action.path}
                url={action.path}
                label={action.label}
              />
            ))}
          </div>
        )}
      </>
    )
  }

  return (
    <div className={`episode-player ${className}`}>
      <h2>{playerLabel}</h2>
      <div className="episode-player__intro">{intro}</div>
      <div className="episode-player__content-wrapper">
        <div ref={viewerRef} className="episode-player__video-wrapper">
          <div className="episode-player__video">{renderCurrentVideo()}</div>
          <div className="episode-player__video-desktop-spacer"></div>
        </div>
        <div className="episode-player__current-data">
          {renderCurrentData()}
        </div>
        <div className="episode-player__episode-wrapper">
          <div className="episode-player__episode-desktop-spacer"></div>
          <div className="episode-player__episodes">{renderEpisodes()}</div>
        </div>
      </div>
    </div>
  )
}

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  intro: PropTypes.element,
  playerLabel: PropTypes.string,
  className: PropTypes.string,
}

EpisodePlayer.defaultProps = {
  playerLabel: "Self-Paced Module Videos",
  className: "",
}

export default EpisodePlayer
