import { Link } from "gatsby"
import React from "react"
import "./styles.scss"

const AnchorNav = () => (
  <nav className="anchor__nav">
    <ul>
      <li>
        <label>Grade Band:</label>
      </li>
      <li>
        <Link to="/educators#ElementarySchool">Elementary School</Link>
      </li>
      <li>
        <Link to="/educators#MiddleSchool">Middle School</Link>
      </li>
      <li>
        <Link to="/educators#HighSchool">High School</Link>
      </li>
    </ul>
  </nav>
)

export default AnchorNav
